import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
// import Dashboard from "./pages/Dashboard";
import BusinessListing from "./pages/BusinessListing";
import Login from "./components/Login";

function App() {
  const location = useLocation(); // Get the current route location

  // Define routes where the Layout (Sidebar & Header) should not be displayed
  const noLayoutRoutes = ["/"];

  return (
    <div>
      {/* Conditionally render Layout for all routes except the ones in noLayoutRoutes */}
      {!noLayoutRoutes.includes(location.pathname) ? (
        <Layout>
          <Routes>
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/business-listing" element={<BusinessListing />} />
          </Routes>
        </Layout>
      ) : (
        // Render only the Login page without Layout
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      )}
    </div>
  );
}

function RootApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default RootApp;
