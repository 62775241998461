import React from "react";
import { Link } from "react-router-dom";
import './../components/sidebar.css'

const Sidebar = () => {
  return (
    <div className="sidebar">
      <img src="/images/Big.jpg"></img>
      <ul>
        {/* <li>
          <Link to="/dashboard">Dashboard</Link>
        </li> */}
        <li>
          <Link to="/business-listing">Business Listing</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
