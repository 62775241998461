import React, { useEffect, useState } from "react";
import axios from "axios";
import TableOne from "../components/TableOne";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import "./../pages/businesslisting.css";

function BusinessListing() {
  const [allBusinesses, setAllBusinesses] = useState([]); // Store all businesses reversed
  const [currentPageBusinesses, setCurrentPageBusinesses] = useState([]); // Businesses on the current page
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null); // Track open dropdown by row ID

  // Fetch all businesses and reverse the data
  const fetchAllBusinesses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://biz.dropyourbusiness.com/api/business/all`
      );

      const totalBusinesses = [];
      const totalPages = response.data.pagination.last_page;

      // Fetch all pages
      for (let page = 1; page <= totalPages; page++) {
        const pageResponse = await axios.get(
          `https://biz.dropyourbusiness.com/api/business/all?page=${page}`
        );
        totalBusinesses.push(...pageResponse.data.businesses);
      }

      // Reverse all businesses globally
      const reversedBusinesses = totalBusinesses.reverse();
      setAllBusinesses(reversedBusinesses);

      // Set the initial page data
      setPagination(response.data.pagination);
      const pageData = reversedBusinesses.slice(0, response.data.pagination.per_page);
      setCurrentPageBusinesses(pageData);
    } catch (error) {
      console.error("Failed to fetch business data", error);
    } finally {
      setLoading(false);
    }
  };

  // Load businesses for a specific page
  const loadPage = (page) => {
    if (!pagination) return;

    const { per_page } = pagination;
    const startIndex = (page - 1) * per_page;
    const endIndex = startIndex + per_page;
    const pageData = allBusinesses.slice(startIndex, endIndex);

    setCurrentPageBusinesses(pageData);
    setPagination((prev) => ({
      ...prev,
      current_page: page,
      prev_page_url: page > 1 ? `?page=${page - 1}` : null,
      next_page_url: page < pagination.last_page ? `?page=${page + 1}` : null,
    }));
  };

  // Initial data fetch
  useEffect(() => {
    fetchAllBusinesses();
  }, []);

  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.patch(`https://biz.dropyourbusiness.com/api/business/${id}/status`, {
        status: newStatus,
      });
      alert(`Status updated to ${newStatus}`);
      setOpenDropdown(null); // Close the dropdown after status update
      // Refresh data after status change
      fetchAllBusinesses();
    } catch (error) {
      console.error("Error updating status", error);
    }
  };

  const toggleDropdown = (id) => {
    setOpenDropdown((prev) => (prev === id ? null : id));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: (row, index) => index + 1,
        id: "index",
      },
      { Header: "Business Name", accessor: "business_name" },
      { Header: "Owner", accessor: "owner_name" },
      { Header: "Category", accessor: "category" },
      { Header: "Sub Category", accessor: "sub_category" },
      {
        Header: "Contact Number",
        accessor: "address.contact_1",
        Cell: ({ value }) => value || "N/A", 
      },
      {
        Header: "Email",
        accessor: "address.email",
        Cell: ({ value }) => value || "N/A", 
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const currentStatus = row.original.status;
          const id = row.original.id; 
          const statusLabels = {
            A: "Accept",
            P: "Pending",
            R: "Reject",
          };

          return (
            <div className="dropdown">
              <button
                className={`btn dropdown-toggle ${
                  currentStatus === "A"
                    ? "btn-success"
                    : currentStatus === "R"
                    ? "btn-danger"
                    : "btn-warning"
                }`}
                type="button"
                onClick={() => toggleDropdown(id)} // Toggle dropdown on click
              >
                {statusLabels[currentStatus]}
              </button>
              {openDropdown === id && ( // Show dropdown if it matches the openDropdown state
                <ul className="dropdown-menu show">
                  {Object.entries(statusLabels).map(([statusKey, label]) => (
                    <li key={statusKey}>
                      <button
                        className="dropdown-item"
                        onClick={() => handleStatusChange(id, statusKey)}
                      >
                        {label}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        },
      },
    ],
    [handleStatusChange, openDropdown]
  );

  return (
    <div className="container mt-4">
      <h1>Business Listing</h1>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <TableOne columns={columns} data={currentPageBusinesses} />
          {pagination && (
            <div className="pagination-container d-flex justify-content-between align-items-center mt-4">
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => loadPage(pagination.current_page - 1)}
                disabled={!pagination.prev_page_url}
              >
                <GrLinkPrevious />
              </button>
              <span>
                Page {pagination.current_page} of {pagination.last_page}
              </span>
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => loadPage(pagination.current_page + 1)}
                disabled={!pagination.next_page_url}
              >
                <GrLinkNext />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BusinessListing;
