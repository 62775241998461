import React, { useState } from "react";
import './../components/login.css';
import { ToastContainer,toast } from "react-toastify";
import { FaEye,FaEyeSlash  } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleLogin = (e) => {
    e.preventDefault();
  
    console.log("Entered Email:", email);
    console.log("Entered Password:", password);
  
    if (email === "admin" && password === "admin123") {
     
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    
      toast.success("Login successful!");
      console.log("success");
      navigate("/business-listing");
    } else {
      toast.error("Something went wrong!");
    }
    
  };
  

  return (
    <div className="login-container">
      {/* <div className="login-logo">
        <img src="/images/Business-Listing-Sites.jpg" alt="Radhe Logo" />
      </div> */}
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <input
              type="text"
              placeholder="Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye/>}
            </span>
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Login;
