import React from "react";
import "./../components/header.css"

const Header = () => {
  return (
    <div className="header">
      <h1>Admin Panel</h1>
      <div className="user-info">
        <span>Hello, Admin</span>
        <img
          src="/images/header.png"
          alt="User Avatar"
          className="user-avatar"
        />
      </div>
    </div>
  );
};

export default Header;
